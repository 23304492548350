<template>
  <v-badge
    :value="!!assignment.needsAttention"
    color="error"
    icon="mdi-exclamation-thick"
    left
    overlap
    bordered
    class="full-width"
  >
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="12" lg="4">
        <v-card class="my-4">
          <v-container fluid class="pa-0" :class="`${this.status.color.split(' ')[0]}-border `">
            <v-row
              class="ma-0 clickable"
              :class="`${this.status.color} white--text ${this.status.color.split(' ')[0]}-border-bottom`"
              @click="$router.push(`/driver/${assignment.id}`)"
            >
              <v-col cols="auto" class="text-center">
                <div class="text-subtitle-1">TRIP #</div>
                <div class="text-h5">{{ assignment.tripRequestId }}</div>
                <v-icon
                  :class="`${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`"
                  v-if="assignment.outOfState"
                >
                  mdi-weather-night
                </v-icon>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="8" class="right">
                <div class="text-subtitle-1 text-right">{{ tripHours }} TOTAL HRS</div>
                <div class="text-subtitle-1 text-right">TOTAL MI</div>
              </v-col>
            </v-row>
            <v-row class="px-2 my-2">
              <v-col cols="6">
                <div class="text-subtitle-1"><v-icon>mdi-account</v-icon>{{ driver }}</div>
                <div class="text-subtitle-1"><v-icon>mdi-bus</v-icon>{{ vehicle }}</div>
                <div class="text-subtitle-1">({{ vehicleType }})</div>
              </v-col>
              <v-col cols="6">
                <div class="text-subtitle-1 text-right">{{ leaveDate }} - {{ leaveTime }}</div>
                <div class="text-subtitle-1 text-right">{{ returnDate }} - {{ returnTime }}</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="12">
                <div class="text-overline font-weight-bold">Route</div>
                <div class="text-subtitle-1 px-4" v-for="(stop, i) of route" :key="i">
                  <v-icon class="mr-2">{{ stop.icon }} </v-icon>{{ stop.name }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="12">
                <div class="text-overline font-weight-bold">Trip Details</div>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1">Type:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">{{ tripTypesById[tripRequest.tripTypeId].name }}</div>
                  </v-col>
                </v-row>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1">Event:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">
                      {{ tripRequest.tripEventIds.map((e) => tripEventsById[e].name).join(', ') }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="12">
                <div class="text-overline font-weight-bold">Passenger Details</div>
                <v-row dense class="px-4">
                  <v-col cols="6"><div class="text-subtitle-1"># Adults/Students:</div></v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">{{ tripRequest.totalAdults }} / {{ tripRequest.totalStudents }}</div>
                  </v-col>
                </v-row>
                <v-row dense class="px-4">
                  <v-col cols="6"><div class="text-subtitle-1">Special Needs Student(s):</div></v-col>
                  <v-col cols="6">
                    <!-- TODO: numSpecialNeedsStudents -->
                    <div class="text-subtitle-1">0</div>
                  </v-col>
                </v-row>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1" v-if="tripRequest.gradeLevels.length">Grades:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1" v-if="tripRequest.gradeLevels.length">
                      {{ grades }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="12">
                <div class="text-overline font-weight-bold">Other</div>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1">Wheelchair Lift:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">
                      {{ tripRequest.numWheelchairSlot > 0 ? 'Yes' : 'No' }}
                    </div>
                  </v-col>
                </v-row>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1">Special Equipment:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">{{ specEq }}</div>
                  </v-col>
                </v-row>
                <v-row dense class="px-4">
                  <v-col cols="6">
                    <div class="text-subtitle-1">Special Indicators:</div>
                  </v-col>
                  <v-col cols="6" class="center-vertical">
                    <div class="text-subtitle-1">{{ specIns }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="12">
                <div class="text-overline font-weight-bold">Notes</div>
                <v-col cols="12" class="center-vertical">
                  <p class="text-subtitle-1" v-if="tripRequest.vehDriverInfo.length">
                    {{ tripRequest.vehDriverInfo }}
                  </p>
                  <p class="text-subtitle-1" v-else>None</p>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
          <template v-if="assignment.status == 0">
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn v-if="showDecline" color="error" @click="accept(false)"> Decline </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="showAccept" color="success" @click="accept(true)"> Accept </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-badge>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { format, differenceInMinutes } from 'date-fns';
import { GET_ASSIGNMENT, ACCEPT_ASSIGNMENT } from '@/store/modules/Assignment/actions';
import { GET_CONFIG } from '@/store/modules/Config/actions';

export default {
  name: 'AssignmentDetailsMobile',
  inject: ['eventHub'],
  components: {},
  props: ['id'],
  data() {
    return {
      format,
      differenceInMinutes,
      assignment: {},
      tripRequest: {},
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('user', ['me', 'usersByEmail']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinations']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('config', ['specialIndicators', 'notifyConfig']),
    status() {
      if (!this.assignment.status) return { display: 'pending', color: 'blue darken-2' };
      if (this.assignment.status == 1 && this.tripRequest.status == 2)
        return { display: 'complete', color: 'orange darken-3' };
      else return { display: 'upcoming', color: 'green darken-1' };
    },
    driver() {
      return this.assignment.driverId ? this.getDriverInfo(this.assignment.driverId) : this.assignment.driver;
    },
    vehicle() {
      if (this.assignment.vehicleId) return this.vehiclesById[this.assignment.vehicleId].name;
      else return '-';
    },
    vehicleType() {
      if (this.tripRequest.vehicleTypeId) return this.vehicleTypesById[this.tripRequest.vehicleTypeId].name;
      else return '-';
    },
    leaveDate() {
      const d = new Date(this.assignment.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    leaveTime() {
      const s = this.assignment.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      const d = new Date(this.assignment.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    returnTime() {
      const s = this.assignment.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    tripHours() {
      const start = new Date(`${this.assignment.leaveDate}, ${this.assignment.leaveTime}`);
      const end = new Date(`${this.assignment.returnDate}, ${this.assignment.returnTime}`);
      const diff = differenceInMinutes(end, start);
      return Math.round((diff / 60 + Number.EPSILON) * 100) / 100;
    },
    route() {
      if (this.tripRequest.stops && this.tripRequest.stops.length)
        return this.tripRequest.stops.map((e) => {
          let icon = 'mdi-map-marker';
          if (e.isLocation) icon = 'mdi-school';
          else if (e.isDestination) icon = 'mdi-map-marker-check';
          return { name: e.name, icon };
        });
      else return [];
    },
    grades() {
      if (this.tripRequest.gradeLevels.length && this.tripRequest.gradeLevels[0] == 'All') return 'All';
      else if (this.tripRequest.gradeLevels.length) return this.tripRequest.gradeLevels.join(', ');
      else return 'N/A';
    },
    specEq() {
      const r = [];
      if (this.tripRequest.numWheelchairSlot > 0) r.push('Wheelchair Slots');
      if (this.tripRequest.numSafetyVest > 0) r.push('Safety Vests');
      if (this.tripRequest.numFoldDownSeat > 0) r.push('Fold Down Seats');
      return r.length ? r.join(', ') : '-';
    },
    specIns() {
      if (!this.tripRequest.specialIndicators) return '-';
      const r = [];
      if (this.specialIndicators.length)
        this.tripRequest.specialIndicators.forEach((e) => {
          const si = this.specialIndicators.find((s) => s.id == e);
          r.push(si ? si.name : '');
        });
      return r.join(', ');
    },
    showAccept() {
      const roleIds = this.me.roles.map((e) => e.id);
      if (this.notifyConfig.driverAccept) return roleIds.includes(5);
      else return roleIds.includes(1) || roleIds.includes(2);
    },
    showDecline() {
      const roleIds = this.me.roles.map((e) => e.id);
      if (this.notifyConfig.driverDecline) return roleIds.includes(5);
      else return roleIds.includes(1) || roleIds.includes(2);
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('assignment', [GET_ASSIGNMENT, ACCEPT_ASSIGNMENT]),
    ...mapActions('config', [GET_CONFIG]),
    async fetchItems() {
      this.assignment = await this.getAssignmentById(this.id);
      this.tripRequest = this.tripRequests.find((e) => e.id == this.assignment.tripRequestId);
    },
    async accept(status) {
      try {
        const r = await this.acceptAssignment({
          assignmentId: this.assignment.id,
          status: status ? 1 : -1,
          // notes: this.notes
        });
        if (r && r.done) {
          this.$myalert.success(`Assignment ${status ? 'accepted' : 'denied'}`);
          this.$router.push('/driver');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    getDriverInfo(driverId) {
      return `${this.driversById[driverId].firstName} ${this.driversById[driverId].lastName}`;
    },
  },
};
</script>

<style lang="scss">
.full-width {
  width: 100%;
}
.clickable:hover {
  cursor: pointer;
}
.green-border {
  border: 5px solid #43a047 !important;
}
.orange-border {
  border: 5px solid #ef6c00 !important;
}
.blue-border {
  border: 5px solid #1976d2 !important;
}
.red-border {
  border: 5px solid #ff5252 !important;
}
.green-border-bottom {
  border-bottom: 5px solid #43a047 !important;
}
.orange-border-bottom {
  border-bottom: 5px solid #ef6c00 !important;
}
.blue-border-bottom {
  border-bottom: 5px solid #1976d2 !important;
}
.red-border-bottom {
  border-bottom: 5px solid #ff5252 !important;
}
.center-vertical {
  align-self: center;
}
</style>
